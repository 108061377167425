import React, { useEffect, useState } from "react";
import { Table, Modal, Row, Col, Card, Form, Button, Space, Input } from "antd";
import { getStockDetailModalPaging } from "../services/product.service";
import Swal from "sweetalert2";

const StockModal = ({ visible, onClose, onSelectProduct, inputValue }) => {
    const productColumns = [
        {
            title: "รหัสสินค้า",
            dataIndex: "productCode",
            key: "productCode",
            sorter: true,
            fixed: 'left',
        },
        {
            title: "ชื่อสินค้า",
            dataIndex: "productName",
            key: "productName",
            sorter: true,
            fixed: 'left',
            width: 200
        },
        {
            title: "วันหมดอายุ",
            dataIndex: "expriedDate",
            key: "expriedDate",
            sorter: true,
        },
        {
            title: "เลขที่ใบรับเข้า",
            dataIndex: "receiveNo",
            key: "receiveNo",
            sorter: true,
        },
        {
            title: "เลขที่เอกสารอ้างอิง",
            dataIndex: "receiveRef",
            key: "receiveRef",
            sorter: true,
        },
        {
            title: "คลัง",
            dataIndex: "warehouseName",
            key: "warehouseName",
            sorter: true,
        },
        {
            title: "แถว",
            dataIndex: "line",
            key: "line",
            sorter: true,
        },
        {
            title: "ชั้น",
            dataIndex: "rack",
            key: "rack",
            sorter: true,
        },
        {
            title: "ล็อค",
            dataIndex: "loc",
            key: "loc",
            sorter: true,
        },
        {
            title: "จำนวนทั้งหมด",
            dataIndex: "total",
            key: "total",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH').format(value),
        },
        {
            title: "จำนวนคงเหลือ",
            dataIndex: "remainTotal",
            key: "remainTotal",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH').format(value),
        },
        {
            title: "ราคาทุน",
            dataIndex: "costPrice",
            key: "costPrice",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
        },
        {
            title: "ราคาส่งร้านค้า",
            dataIndex: "costShop",
            key: "costShop",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
        },
        {
            title: "ราคาส่งเกษตรกร",
            dataIndex: "farmerPrice",
            key: "farmerPrice",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
        },
        {
            title: "ราคาปลีก",
            dataIndex: "retailPrice",
            key: "retailPrice",
            sorter: true,
            render: (value) => new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
        },
    ];
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [sortField, setSortField] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [search, setSearch] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(() => {
        if (visible) {
            // Set initial values when the modal is opened
            form.setFieldsValue({ search: inputValue });
            setSearch(inputValue || '');
            fetchData({
                currentPage,
                sortColumn: sortField ?? '',
                sortDirection: sortOrder ?? '',
                perPage,
                search: inputValue || '',
                isClear: false,
            });
        } else {
            // Clear state when the modal closes
            clearSelection();
            form.resetFields();
            setSearch('');
            setData([]);
            setSelectedRow(null);
            setCurrentPage(1);
            setSortField(null);
            setSortOrder(null);
        }
    }, [visible]);

    const fetchData = async (params) => {
        const response = await getStockDetailModalPaging({
            currentPage: params.currentPage || 1,
            perPage: params.perPage || 10,
            sortColumn: params.sortColumn || '',
            sortDirection: params.sortDirection || '',
            search: params.isClear ? '' : params.search || search,
        });
        if (response?.data) {
            setData(response.data.data);
            setTotalRows(response.data.total);
        }
    };

    const onSelect = () => {
        if (!selectedRow) {
            Swal.fire('กรุณาเลือกสินค้า', '', 'error');
            return;
        }
        onSelectProduct(selectedRow);
        onClose(); // Close the modal
    };

    const onChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        setPerPage(pagination.pageSize);
    };

    const clearSearch = () => {
        form.setFieldsValue({ search: '' });
        setSearch('');
        fetchData({ isClear: true });
    };

    const clearSelection = () => {
        setSelectedRow(null);
        setSelectedRowKeys([]);
    };

    const handleRowClick = (record) => {
        setSelectedRow(record);
        setSelectedRowKeys([record.key]); // Assume `key` is a unique identifier
    };

    return (
        <Modal
            title="เลือกสินค้า"
            open={visible}
            onCancel={onClose}
            destroyOnClose={false}
            footer={[
                <Button key="back" type="primary" danger onClick={onClose}>
                    ยกเลิก
                </Button>,
                <Button key="submit" type="primary" style={{ backgroundColor: "#125a05" }} onClick={onSelect}>
                    เลือกสินค้า
                </Button>,
            ]}
            centered
            width={1400}
        >
            <Row className="row-padding-10">
                <Col span={24}>
                    <Card title="ค้นหา" bordered={false}>
                        <Form form={form}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label="ค้นหา"
                                        name="search"
                                    >
                                        <Input
                                            placeholder="ค้นหา รหัสสินค้า , ชื่อสินค้า , รายละเอียด"
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-3">
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space size="middle">
                                        <Button type="primary" style={{ backgroundColor: "#125a05" }} size="large" onClick={() => fetchData({})}>
                                            ค้นหา
                                        </Button>
                                        <Button type="primary" danger size="large" onClick={clearSearch}>
                                            ล้าง
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row className="row-padding-10">
                <Col span={24}>
                    <Table
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys,
                            onChange: (_, selectedRows) => {
                                setSelectedRow(selectedRows[0]);
                                setSelectedRowKeys([selectedRows[0]?.key]);
                            },
                        }}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                        dataSource={data}
                        columns={productColumns}
                        onChange={onChange}
                        pagination={{
                            current: currentPage,
                            pageSize: perPage,
                            total: totalRows,
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default StockModal;
