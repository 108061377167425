import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'purchaseorder/'
const getPurchaseOrderPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode +
        '&supplierId=' +
        param?.supplierId +
        '&purchaseDateStart=' +
        param?.purchaseDateStart +
        '&purchaseDateEnd=' +
        param?.purchaseDateEnd +
        '&receiveDateStart=' +
        param?.receiveDateStart +
        '&receiveDateEnd=' +
        param?.receiveDateEnd +
        '&statusId=' +
        param?.statusId +
        '&paymentStatusId=' +
        param?.paymentStatusId,
        { headers: authHeader() },
    )
}
const createPurchaseOrder = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updatePurchaseOrder = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deletePurchaseOrder = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getPurchaseOrderById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}

export {
    getPurchaseOrderPaging,
    createPurchaseOrder,
    updatePurchaseOrder,
    deletePurchaseOrder,
    getPurchaseOrderById,
}