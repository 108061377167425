import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'invoice/'
const getInvoicePaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode +
        '&customerId=' +
        param?.customerId +
        '&invoiceDateStart=' +
        param?.invoiceDateStart +
        '&invoiceDateEnd=' +
        param?.invoiceDateEnd +
        '&statusId=' +
        param?.statusId +
        '&paymentStatusId=' +
        param?.paymentStatusId,
        { headers: authHeader() },
    )
}
const createInvoice = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateInvoice = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteInvoice = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getInvoiceById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}

export {
    getInvoicePaging,
    createInvoice,
    updateInvoice,
    deleteInvoice,
    getInvoiceById,
}