import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'status/'
const getStatusPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode,
        { headers: authHeader() },
    )
}
const createStatus = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateStatus = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteStatus = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getStatusById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}
const getPaymentSelect = async () => {
    return await axios.get(API_URL + 'GetPaymentSelect', { headers: authHeader() })
}
const getPoSelect = async () => {
    return await axios.get(API_URL + 'GetPoSelect', { headers: authHeader() })
}
const getQoSelect = async () => {
    return await axios.get(API_URL + 'GetQoSelect', { headers: authHeader() })
}
const getIvSelect = async () => {
    return await axios.get(API_URL + 'GetIvSelect', { headers: authHeader() })
}
export {
    getStatusPaging,
    createStatus,
    updateStatus,
    deleteStatus,
    getStatusById,
    getPaymentSelect,
    getPoSelect,
    getQoSelect,
    getIvSelect,
}