import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  Table,
  InputNumber,
  Menu,
  Dropdown
} from "antd";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  createInvoice,
  getInvoiceById,
  updateInvoice,
} from "../../services/invoice.service";
import dayjs from "dayjs";
import locale from "dayjs/plugin/localeData"; // Import the localeData plugin
import "dayjs/locale/th"; // Import the desired locale (e.g., Thai)
import { handleKeyDecimalPress } from "../../services/helper.service";
import { getTransportSelect } from "../../services/transport.service";
import { getPaymentTypeSelect } from "../../services/payment-type.service";
import FormToPrint from "./FormToPrint";
import FormOpToPrint from "./FormOpToPrint";
import FormFtToPrint from "./FormFtToPrint";
import { getCustomerSelect } from "../../services/customer.service";
import StockModal from "../../components/StockModal";
import { getPaymentSelect, getIvSelect } from "../../services/status.service";
import FormDvToPrint from "./FormDvToPrint";
// Extend Day.js with the localeData plugin
dayjs.extend(locale);
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const InvoiceManage = () => {
  dayjs.locale("th");
  const componentRef = useRef(); // Reference to the component
  const componentOpRef = useRef(); // Reference to the component
  const componentDvRef = useRef(); // Reference to the component
  const componentFtRef = useRef(); // Reference to the component

  const handlePrint = () => {
    // Open a new window
    const printWindow = window.open("", "_blank");

    // Create the printable content (this would be the receipt)
    const printableContent = componentRef.current.innerHTML;

    // Write content into the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>INVOICE RECEIPT</title>
          <style>
            body { font-family: Arial, sans-serif; font-size: 12px; padding: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
      </html>
    `);

    // Close the document to finish writing
    printWindow.document.close();

    // Focus on the new window
    printWindow.focus();

    // Trigger print in the new window
    printWindow.print();
  };
  const handleFtPrint = () => {
    // Open a new window
    const printWindow = window.open("", "_blank");

    // Create the printable content (this would be the receipt)
    const printableContent = componentFtRef.current.innerHTML;

    // Write content into the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>FULLTAX RECEIPT</title>
          <style>
            body { font-family: Arial, sans-serif; font-size: 12px; padding: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
      </html>
    `);

    // Close the document to finish writing
    printWindow.document.close();

    // Focus on the new window
    printWindow.focus();

    // Trigger print in the new window
    printWindow.print();
  };
  const handleOpPrint = () => {
    // Open a new window
    const printWindow = window.open("", "_blank");

    // Create the printable content (this would be the receipt)
    const printableContent = componentOpRef.current.innerHTML;

    // Write content into the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Order Picking</title>
          <style>
            body { font-family: Arial, sans-serif; font-size: 12px; padding: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
      </html>
    `);

    // Close the document to finish writing
    printWindow.document.close();

    // Focus on the new window
    printWindow.focus();

    // Trigger print in the new window
    printWindow.print();
  };
  const handleDvPrint = () => {
    // Open a new window
    const printWindow = window.open("", "_blank");

    // Create the printable content (this would be the receipt)
    const printableContent = componentDvRef.current.innerHTML;

    // Write content into the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>DELIVERY RECEIPT</title>
          <style>
            body { font-family: Arial, sans-serif; font-size: 12px; padding: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
      </html>
    `);

    // Close the document to finish writing
    printWindow.document.close();

    // Focus on the new window
    printWindow.focus();

    // Trigger print in the new window
    printWindow.print();
  };
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [detail, setDetail] = useState([]);
  const [data, setData] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [transportList, setTransportList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const addHandle = () => {
    const newDataObject = {
      invoiceDetailId: uuidv4(),
      stockId: null,
      productCode: null,
      productName: null,
      total: null,
      productPrice: null,
      remark: null,
      price: null,
    };

    setDetail([...detail, newDataObject]);
  };
  const deleteRowById = (idToDelete) => {
    const updatedData = detail?.filter(
      (item) => item.invoiceDetailId !== idToDelete.invoiceDetailId
    );
    setDetail(updatedData);
  };
  const loadDataDropDown = async () => {
    const { data: customer } = await getCustomerSelect();
    setCustomerList(customer);

    const { data: transport } = await getTransportSelect();
    setTransportList(transport);

    const { data: paymentType } = await getPaymentTypeSelect();
    setPaymentTypeList(paymentType);

    const { data: status } = await getIvSelect();
    setStatusList(status);

    const { data: paymentStatus } = await getPaymentSelect();
    setPaymentStatusList(paymentStatus);
  };
  const handleDetailChange = (value, fieldName, id) => {
    let d = detail.find((value) => value.invoiceDetailId === id);
    if (fieldName === "productCode") {
      d.productCode = value;
      d.productName = null;
      d.total = null;
      d.productPrice = null;
      d.remark = null;
      d.stockId = null;
      d.price = null;
    } else if (fieldName === "productName") {
      d.productName = value;
    } else if (fieldName === "total") {
      d.total = value ?? 0;
    } else if (fieldName === "productPrice") {
      d.productPrice = value ?? 0;
    } else if (fieldName === "remark") {
      d.remark = value;
    }
    d.price = d.total * d.productPrice;
    setDetail([...detail]);
  };
  const calculateSumTotal = () => {
    const vat = parseFloat(form.getFieldValue("vat")) || 0; // Ensure vat is a number
    const total = detail?.reduce((acc, item) => {
      const price = parseFloat(item.price);
      return acc + (isNaN(price) ? 0 : price); // Add only valid prices
    }, 0.0);

    form.setFieldsValue({
      total: vat > 0 ? (vat / 100) * total + total : total,
    });
  };

  useEffect(() => {
    calculateSumTotal();
  }, [detail]);
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedRow, setSelectedRow] = useState();
  const handleSelectProduct = (record) => {
    setDetail((prev) =>
      prev.map((item) =>
        item.invoiceDetailId === selectedRow.invoiceDetailId
          ? {
            ...item,
            stockId: record.stockId,
            productCode: record.productCode,
            productName: record.productName,
            productPrice: record.retailPrice,
            total: 1,
            price: record.retailPrice * 1,
          }
          : item
      )
    );
    setModalVisible(false);
  };
  const columns = [
    {
      title: "#",
      width: 50,
      align: "center",
      render: (row) => (
        <div>
          <Button
            key={row.invoiceDetailId}
            type="text"
            icon={<DeleteOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "red",
            }}
            onClick={() => {
              deleteRowById(row);
            }}
          />
        </div>
      ),
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "productCode",
      key: "productCode",
      width: 250,
      render: (text, record) => (
        <>
          <Input
            key={`productCode_${record.invoiceDetailId}`}
            value={record.productCode}
            onChange={(e) =>
              handleDetailChange(
                e.target.value,
                "productCode",
                record.invoiceDetailId
              )
            }
            style={{ width: "75%", marginRight: 5 }}
          />
          <Button
            type="text"
            icon={<SearchOutlined />}
            style={{
              fontSize: "16px",
              width: 50,
              height: 50,
              color: "#125a05",
            }}
            onClick={() => {
              setSelectedRow(record);
              setInputValue(record.productCode);
              setModalVisible(true);
            }}
          />
        </>
      ),
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (text, record) => (
        <Input
          className="read-only"
          value={record.productName}
          readOnly
          style={{ width: "98%", marginRight: 5 }}
        />
      ),
    },
    {
      title: "จำนวน",
      dataIndex: "total",
      key: "total",
      sorter: false,
      width: 200,
      render: (text, record) => (
        <InputNumber
          min={0}
          key={`total_${record.invoiceDetailId}`}
          value={record?.total}
          onChange={(value) =>
            handleDetailChange(value, "total", record.invoiceDetailId)
          }
          style={{ width: "98%", textAlign: "right", marginRight: 5 }}
        />
      ),
    },
    {
      title: "ราคาสินค้า",
      dataIndex: "productPrice",
      key: "productPrice",
      sorter: false,
      width: 200,
      render: (text, record) => (
        <Input
          key={`productPrice_${record.invoiceDetailId}`}
          value={record?.productPrice}
          onChange={(e) =>
            handleDetailChange(
              e.target.value,
              "productPrice",
              record.invoiceDetailId
            )
          }
          style={{ width: "98%", textAlign: "right", marginRight: 5 }}
          onKeyPress={handleKeyDecimalPress}
        />
      ),
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "price",
      key: "price",
      sorter: false,
      width: 200,
      render: (text, record) => (
        <Input
          className="read-only"
          key={`price_${record.invoiceDetailId}`}
          value={record?.price}
          onChange={(e) =>
            handleDetailChange(e.target.value, "price", record.invoiceDetailId)
          }
          style={{ width: "98%", textAlign: "right", marginRight: 5 }}
          onKeyPress={handleKeyDecimalPress}
        />
      ),
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 300,
      sorter: false,
      render: (text, record) => (
        <Input
          maxLength={100}
          key={"remark_" + record.invoiceDetailId}
          value={record?.remark}
          onChange={(e) => {
            handleDetailChange(
              e.target.value,
              "remark",
              record.invoiceDetailId
            );
          }}
          style={{ width: "98%" }}
        />
      ),
    },
  ];
  const onFinish = async (data) => {
    if (detail.length === 0) {
      Swal.fire(
        "กรุณาเพิ่มรายละเอียดการจัดรับสินค้าอย่างน้อย 1 รายการ",
        "",
        "error"
      );
    } else if (detail.find((x) => x.stockId === null)) {
      Swal.fire("กรุณาเลือกสินค้าในรายละเอียด", "", "error");
    } else if (
      detail.find((x) => x.total === null || x.total === "" || x.total === 0)
    ) {
      Swal.fire("กรุณากรอกจำนวนในรายละเอียด", "", "error");
    } else if (
      detail.find(
        (x) =>
          x.productPrice === null ||
          x.productPrice === "" ||
          x.productPrice === 0
      )
    ) {
      Swal.fire("กรุณากรอกราคาในรายละเอียด", "", "error");
    } else {
      let timerInterval;
      var param = {
        invoiceId: invoiceId ?? "",
        invoiceDate: data.invoiceDate
          ? dayjs(data.invoiceDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        sendOrderDate: data.sendOrderDate
          ? dayjs(data.sendOrderDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        customerId: data.customerId,
        paymentTypeId: data.paymentTypeId,
        transportId: data.transportId,
        remark: data.remark,
        vat: data.vat,
        total: data.total,
        statusId: data.statusId,
        paymentStatusId: data.paymentStatusId,
        details: detail,
      };

      if (location?.state?.invoiceId) {
        const { data: updateData } = await updateInvoice(param);
        if (updateData.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูล",
            text: "บันทึกสำเร็จ",
            html: "กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.",
            timer: 500,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire(updateData?.message, "", "error");
        }
      } else {
        const { data: insertData } = await createInvoice(param);
        if (insertData.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูล",
            text: "บันทึกสำเร็จ",
            html: "กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.",
            timer: 500,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              setInvoiceId(insertData?.id);
              navigate("/invoice/manage", {
                state: { invoiceId: insertData?.id },
              });
              window.location.reload();
            }
          });
        } else {
          Swal.fire(insertData?.message, "", "error");
        }
      }
    }
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handlePrint}>
        พิมพ์ใบเสร็จ
      </Menu.Item>
      <Menu.Item key="2" onClick={handleOpPrint}>
        พิมพ์ใบหยิบของ
      </Menu.Item>
      <Menu.Item key="3" onClick={handleDvPrint}>
        พิมพ์ใบส่งของ
      </Menu.Item>
      <Menu.Item key="4" onClick={handleFtPrint}>
        พิมพ์ใบกำกับภาษี
      </Menu.Item>
    </Menu>
  );
  const [invoiceId, setInvoiceId] = useState();
  const loadData = async (param) => {
    const { data } = await getInvoiceById(param);
    if (data) {
      form.setFieldsValue({
        invoiceId: data.invoiceId,
        invoiceNo: data.invoiceNo,
        quotationRefNo: data.quotationRefNo,
        paymentTypeId: data?.paymentTypeId,
        transportId: data?.transportId,
        invoiceDate: dayjs(data.invoiceDate),
        sendOrderDate: dayjs(data.sendOrderDate),
        customerId: data.customerId,
        remark: data.remark,
        vat: data.vat,
        total: data.total,
        statusId: data.statusId,
        paymentStatusId: data.paymentStatusId,
      });
      setData(data);
      setInvoiceId(data.invoiceId);
      setDetail(data?.details);
    }
  };
  useEffect(() => {
    loadDataDropDown();
    if (location?.state?.invoiceId) {
      loadData(location?.state?.invoiceId);
    } else {
      form.setFieldsValue({ vat: 7 });
    }
  }, []);
  return (
    <>
      <div style={{ display: "none" }}>
        <FormToPrint ref={componentRef} prop={data} />
        <FormDvToPrint ref={componentDvRef} prop={data} />
        <FormOpToPrint ref={componentOpRef} prop={data} />
        <FormFtToPrint ref={componentFtRef} prop={data} />
      </div>
      <StockModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelectProduct={handleSelectProduct}
        inputValue={inputValue}
      />
      <Card title="จัดการใบเสร็จ" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`เลขที่เอกสาร`} name="invoiceNo">
                <Input readOnly className="read-only" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`เลขที่เอกสารใบเสนอราคา`} name="quotationRefNo">
                <Input readOnly className="read-only" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ลูกค้า`}
                name="customerId"
                rules={[{ required: true, message: "กรุณาเลือกลูกค้า" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={customerList}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  optionFilterProp="children"
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`วันที่ออกใบเสร็จ`}
                name="invoiceDate"
                rules={[
                  { required: true, message: "กรุณาเลือกวันที่ออกใบเสร็จ" },
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder="yyyy-MM-dd HH:mm:ss"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ประเภทการชำระ`}
                name="paymentTypeId"
                rules={[{ required: true, message: "กรุณาเลือกประเภทการชำระ" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={paymentTypeList}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  optionFilterProp="children"
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`วันที่ส่งสินค้า`}
                name="sendOrderDate"
                rules={[
                  { required: true, message: "กรุณาเลือกวันที่ส่งสินค้า" },
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder="yyyy-MM-dd HH:mm:ss"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`หมายเหตุ`} name="remark">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ขนส่ง`} name="transportId">
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={transportList}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  optionFilterProp="children"
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={`ภาษีมูลค่าเพิ่ม (Vat)`} name="vat">
                <InputNumber
                  min={0}
                  max={20}
                  style={{ width: "100%" }}
                  onChange={() => calculateSumTotal()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`จำนวนเงินรวมทั้งสิ้น`} name="total">
                <Input readOnly className="read-only" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สถานะ`}
                name="statusId"
                rules={[{ required: true, message: "กรุณาเลือกสถานะ" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={statusList}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  optionFilterProp="children"
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สถานะการชำระ`}
                name="paymentStatusId"
                rules={[{ required: true, message: "กรุณาเลือกสถานะ" }]}
              >
                <Select
                  placeholder="กรุณาเลือก"
                  style={{ width: "100%" }}
                  options={paymentStatusList}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  optionFilterProp="children"
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col className="gutter-row" xs={12} lg={2}>
              <Button
                className="ant-btn-submit"
                icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
                onClick={() => addHandle()}
                size="large"
                style={{ color: "#FFFFFF", border: "#555843", width: 120 }}
              >
                เพิ่ม
              </Button>
            </Col>
            {invoiceId ? (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button
                  className="ant-btn-print"
                  icon={<PrinterOutlined style={{ color: "#FFFFFF" }} />}
                  size="large"
                  style={{ color: "#FFFFFF", border: "#555843" }}
                >
                  พิมพ์เอกสาร
                </Button>
              </Dropdown>) : null}
          </Row>
          <Row gutter={24} style={{ marginTop: "10px" }}>
            <Col className="gutter-row" span={24}>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <Table
                  columns={columns}
                  scroll={{ x: 500 }}
                  dataSource={detail}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    กลับ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default InvoiceManage;
