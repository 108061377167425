import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "antd";
import { Line } from "@ant-design/charts";
const Dashboard = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const now = new Date();
  const monthName = now.toLocaleString("th-TH", { month: "long" });
  const year = now.toLocaleString("en-US", { year: "numeric" });
  const data = [
    { month: "Jan", value: 30 },
    { month: "Feb", value: 50 },
    { month: "Mar", value: 70 },
    { month: "Apr", value: 60 },
    { month: "May", value: 90 },
    { month: "Jun", value: 120 },
    { month: "Jul", value: 110 },
    { month: "Aug", value: 95 },
    { month: "Sep", value: 80 },
    { month: "Oct", value: 100 },
    { month: "Nov", value: 75 },
    { month: "Dec", value: 85 },
  ];

  // Configuration for the line chart
  const config = {
    data,
    xField: "month",
    yField: "value",
    point: {
      size: 5,
      shape: "circle",
    },
    smooth: true, // For smooth curves
    tooltip: {
      showMarkers: true,
    },
    autoFit: true,
    height: 300,
    color: "#ff4d4f", // Custom color for the line
  };

  // Update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  // Format date and time in Thai locale
  const formattedDate = currentTime.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  const formattedTime = currentTime.toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Card title="วัน-เวลา ปัจจุบัน" bordered={false}>
            {formattedDate} เวลา: {formattedTime}
          </Card>
        </Col>
        <Col span={6}>
          <Card title="ยอดขายวันนี้" bordered={false}>
            {0}
          </Card>
        </Col>
        <Col span={6}>
          <Card title={"ยอดขายเดือน " + monthName} bordered={false}>
            {0}
          </Card>
        </Col>
        <Col span={6}>
          <Card title={"ยอดขายปี " + year} bordered={false}>
            {0}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            title="ยอดขายปีนี้"
            style={{ width: "100%", margin: "20px auto" }}
          >
            <Line {...config} />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
