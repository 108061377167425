import dayjs from 'dayjs';
import React from 'react';
import { formatNumber } from '../../services/helper.service';

const FormOpToPrint = React.forwardRef((props, ref) => {
    const itemsPerPage = 10;
    const totalItems = props?.prop?.details?.length; // Replace with your dynamic items count
    const pages = Math.ceil(totalItems / itemsPerPage);

    const renderPage = (pageNumber) => {

        const startIdx = pageNumber * itemsPerPage;
        const endIdx = Math.min(startIdx + itemsPerPage, totalItems);
        return (
            <div style={{ pageBreakAfter: 'always', padding: "20px", fontFamily: "Arial, sans-serif", fontSize: "12px" }}>
                {/* Header Section */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <div style={{ textAlign: "left" }}>
                        <h2>{props?.prop?.company?.companyName}</h2>
                        <p>{props?.prop?.company?.address}</p>
                        <p>{props?.prop?.company?.tel}</p>
                        <p>เลขที่ผู้เสียภาษี: {props?.prop?.company?.taxNo}</p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <h1>ใบหยิบสินค้า</h1>
                    </div>
                </div>

                {/* Supplier and Order Info */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <div style={{ textAlign: "left" }}>
                        <p>ชื่อลูกค้า: {props?.prop?.customer?.customerName}</p>
                        <p>{props?.prop?.customer?.address}</p>
                        <p>{props?.prop?.customer?.tel}</p>
                        <p>เลขที่ผู้เสียภาษี: {props?.prop?.customer?.taxNo}</p>
                        <p>หมายเหตุ: {props?.prop?.remark}</p>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <p>เลขที่ใบเสร็จ: {props?.prop?.invoiceNo}</p>
                        <p>เลขที่ใบเสนอราคา: {props?.prop?.quotationRefNo}</p>
                        <p>วันที่ออกใบเสร็จ: {props?.prop?.invoiceDateText}</p>
                        <p>วันที่ส่งของ: {props?.prop?.sendOrderDateText}</p>
                        <p>ประเภทการชำระ: {props?.prop?.paymentTypeName}</p>
                        <p>ประเภทการขนส่ง: {props?.prop?.transportName}</p>
                    </div>
                </div>

                {/* Table Section */}
                <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px", fontSize: '10px' }}>
                    <thead style={{ pageBreakInside: 'avoid' }}>
                        <tr>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>No.</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>รหัสสินค้า/รายละเอียด</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>คลัง</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>แถว</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>ชั้น</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>ล็อค</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>จำนวน</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>จำนวนหยิบ</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>หมายเหตุ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.prop?.details?.slice(startIdx, endIdx).map((_, index) => (
                            <tr key={startIdx + index}>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{startIdx + index + 1}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.productCode} {_?.product?.productName} {formatNumber(_?.product?.productWeight)} {_?.product?.unitName}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.warehouseName}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.line}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.rack}</td>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{_?.product?.loc}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>{formatNumber(_?.total)}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}></td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{_?.remark}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Signature Section */}
                {(pageNumber === 0 && pages === 1) || (pageNumber === pages - 1 && pages > 1) ?
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", marginTop: "20px", pageBreakInside: 'avoid' }}>
                        <div style={{ textAlign: "center" }}>
                            <p>ผู้หยิบสินค้า</p>
                            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                                <p>_____________________</p>
                            </div>
                            <p>(ชื่อผู้หยิบสินค้า)</p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p>ผู้อนุมัติ</p>
                            <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                                <p>_____________________</p>
                            </div>
                            <p>(ชื่อผู้อนุมัติ)</p>
                        </div>
                    </div>
                    : <></>}
                {/* Footer */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", pageBreakInside: 'avoid' }}>
                    <p>พิมพ์วันที่: {dayjs().format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>หน้าที่: {pageNumber + 1} </p>
                </div>
            </div>
        );
    };

    return (
        <div ref={ref}>
            {Array.from({ length: pages }).map((_, pageNumber) => renderPage(pageNumber))}
        </div>
    );
});

export default FormOpToPrint;